.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
}

.mobile-tab {
  display: grid;
  place-items: center;
  padding:3px;
}

.table-tr {
  display: grid;
  grid-template-columns:1fr ;
  width: 100%;
  margin: 0 auto;
}
.mobile-table {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin: 0 auto;
  justify-self: center;
}
.table-item {
  display: grid;
  grid-template-columns: 1fr 1.33fr;
  text-align: right;
  width: 100%;
  place-items: center;
  width: 100%;
  height: 100%;
}
.title {
    display: grid;
    place-items: center;
    background: #e9ecef4d;
    width: 100%;
    height: 100%;
    color: grey;
    border-bottom: 1px solid #80808042;
    border-left: 1px solid #80808042;
}
.contanct {
    display: grid;
    place-items: center;
    background: white;
    width: 100%;
    height: 100%;
    text-align: center;
    border-bottom: 1px solid #80808042;
}

.contanct .addressCont a {

  margin: 0 10px;

}

.contanct .addressCont a img {

  width: 30px;

}

/* 
@media only screen and (min-width: 600px) {
  .mobile-tab {
    display:none;
  }
} */