@import "./index.scss";

@font-face {
    font-family: "fb";
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    src: url('../fonts/OpenSansHebrew-Regular.woff') format("opentype"); /* here you go, IE */
} 

@font-face {
    font-family: "fb_regular";
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    src: url('../fonts/OpenSansHebrew-Regular.woff') format("opentype"); /* here you go, IE */
} 

@font-face {
    font-family: "fb_bold";
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    src: url('../fonts/OpenSansHebrew-Bold.woff') format("opentype"); /* here you go, IE */
}


@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v15/CrYjSnGjrRCn0pd9VQsnFOvvDin1pK8aKteLpeZ5c0A.woff) format('woff');
}

body {
    direction: rtl !important;
    text-align: right !important;
    font-family: "fb","helvetica","arial" !important;
    padding: 0px 0 0px 0;
    margin: 0;
    font-size: 19px !important;
    color: black !important;
    /* min-width: 1200px; */
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    background: #f5f5f5;
    /* background: url('https://apinathan.bitcode.co.il/assets/img/default/screenBg.png') repeat; */
}

.boldTypeFamily {
    font-family: "fb_bold","helvetica","arial";
}

.regularTypeFamily {
    font-family: "fb_regular","helvetica","arial";
}

/* .body-container, */

i.fas {
    margin: 0 5px;
}

.content-wrapper
{
    padding: 0px 0px 0 0;
    min-height: 700px;
    min-width: 1000px;
}

.body-container.mobile,
.mobile .content-wrapper
{
    min-height: 400px;
    min-width: inherit;
}

.body-container.mobile {
    padding: 64px 0 0 0;
}

.mobile .content-wrapper {
    padding: 0;
}


.hidden {
    display: none;
}

img {
    max-width: 100%;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

}


p {direction: rtl;}

strong {
    font-family: "fb","Roboto","helvetica","arial";
    font-weight: bold;
}


.wrapper {
    display: block;
    width: 1200px;
    margin: 0 auto;
    position: relative;
}

.wrapper:before {
    content: '';
    display: block;
    width: 100%;
    clear: both;
}

.wrapper:after {
    content: '';
    display: block;
    clear: both;
}

.absoluteWrapper {
    position: absolute;
    right: 50%;
    margin: 0 -600px 0 0;
    top: 0;
}


.clear:before {
    content: '';
    display: block;
    clear: both;
}
.clear:after {
    content: '';
    display: block;
    clear: both;
}


header, section, footer, aside, nav, article, figure, figcaption,
hgroup{
    display: block;
}

section {
    position: relative;
}

img, img a{border:none;}

.colTable {
    display: table-cell;
}

.fullWidth {
    width: 100%;
}

.bgs {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}


/* ::-webkit-input-placeholder {padding-top: 0px;color: white !important; font-size: 80%;}
:-moz-placeholder {padding-top: 0px;color:  white !important; font-size: 80%;}
::-moz-placeholder {padding-top: 0px;color:  white !important; font-size: 80%;}
:-ms-input-placeholder {padding-top: 0px;color: white !important; font-size: 80%;} */


.form-wrapper .MuiFormControl-root {
    width: 100%;
    margin: 0px;
    padding: 0;
}

.form-wrapper .makeStyles-container-1 {
    display: inline-block;
}



.form-wrapper .MuiInputBase-input.MuiInput-input {
    font-family: "fb","helvetica","arial";    
    font-size: 18px;
    
}


.form-wrapper .MuiFormLabel-root {
    right: 0;
}


.form-wrapper .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    text-align: right;
    font-family: "fb","helvetica","arial";    
    font-size: 18px;
}

.form-wrapper .MuiFormLabel-root.Mui-focused,
.form-wrapper .MuiFormLabel-root.MuiFormLabel-filled
{
    right: -65px !important;
}

.form-wrapper > div {
    display: inline-block;
}

.form-wrapper .MuiFormHelperText-root {
    text-align: right;
}

.form-wrapper .MuiFormHelperText-root span {
    color: red;
}


.form-wrapper > div > span {
    display: block;
    color: red;
    padding: 20px 0;
}

.form-wrapper button.sendBtn {
    font-family: "fb_regular","helvetica","arial";    
    font-size: 18px;
    border-color: none;
    color: white;

    border-radius:6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px; 

    background: #e02b79;
    vertical-align: top;
    margin: 0px 0px 30px 0;
    padding: 15px 60px;
}

.form-wrapper button.sendBtn:hover,
.form-wrapper button.sendBtn:focus
 {
    background:black;
    color: white;

}

.form-wrapper button.sendBtn.btn-danger {
    background: #a00;
    color: yellow;
}


.form-wrapper .MuiInput-underline::after {
    border-color: black !important;
}

.form-wrapper .MuiFormLabel-root.MuiFormLabel-filled {
    color: black !important;
}

.form-wrapper .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    color:black ;
}


.owl-carousel .owl-nav {
    top: 0;
    position: absolute;
    width: 100%;
    margin: 20% 0 0 0;
}

.owl-carousel .owl-nav span {
    display: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-prev:focus
{
    /* background: url('../img/icons/left.svg') center center no-repeat ; */
    background-size: 100%;
    width: 28px;
    height: 28px;
    left: -50px;
    position: absolute;
}
.owl-carousel .owl-nav button.owl-next,
.owl-theme .owl-nav button.owl-next:hover,
.owl-theme .owl-nav button.owl-next:focus
{
    /* background: url('../img/icons/right.svg') center center no-repeat; */
    background-size: 100%;
    width: 28px;
    height: 28px;
    position: absolute;
    right: -50px;
}

.owl-theme .owl-dots {
    margin: 20px 0;
}

.owl-theme .owl-dots .owl-dot span {
    margin: 5px 3px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span,
.owl-theme .owl-dots .owl-dot:focus span
{
    background: #e02b79;
}

.credit {
    text-align: center;
    padding: 10px 0;

}

.credit img {
    width: 150px;
}


.Page404 {
    text-align: center;
    padding: 80px 0;
}

.Page404 img {
    width: 400px;
}

.Page404 a:hover img, 
.Page404 a:focus img {
    width: 410px;
}


@keyframes bounce2 {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10% {
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
   
}

.bounce {
    -moz-animation: bounce2 2s infinite;
    -webkit-animation: bounce2 2s infinite;
    animation: bounce2 2s infinite;
}



@keyframes bounce1 {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    40% {
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    60% {
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.bounce1 {
    -moz-animation: bounce1 2s infinite;
    -webkit-animation: bounce1 2s infinite;
    animation: bounce1 2s infinite;
}





h1 {
    margin: 0 0 20px 0;
    font-size: 32px;
    /* font-family: "open_cond","helvetica","arial"  !important;; */
    font-weight: 100;
    
}


.AccPage img.logo {
    width: 100px;
}

.AccPage .columns {
    padding: 50px 0;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
}

.AccPage .columns .text1 {
    margin: 0 0 20px 0;
}

.AccPage .columns .text2 {
    margin: 20px 0 0 0;
}

.AccPage .columns .text2 a {
    margin: 0 0 10px 0;
}

.AccPage .columns h3 {
    font-size: 18px;
    font-family: "fb_regular","helvetica","arial";
}

/*  MENU */

.navbar {
    padding-right: 10px;
}

.navbar .logOutBtn {
    margin: 0 0px 0 20px;
    padding: 0;
    font-size: 20px;
    background: none;
    border: none;
    color: white;
}
.navbar-brand {
    margin: 0 20px 0 0px;
}

.navbar-brand img {
    width: 100px;

}

/* END MENU */

.loginPage .container {
    padding-top: 20px;
}

.loginPage header {
    background:rgba(255, 255, 255, .8);
    padding: 20px 0;
    border-bottom: 1px solid #c5c5c5;
    margin: 0 0 20px 0;
}

.loginPage .logo {
    width: 200px;
    display: block;
    margin: 0 auto 0px;
}

.loginPage .credit {
    position: fixed;
    bottom: 0;
    margin: 0 0 20px 0;
    width: 100%;
}

.HeaderDefault {
    width: 100%;
    background: rgba(0, 0, 0, .3);
    margin: 0 0 20px 0;
    padding: 5px 0;
    
}

.HeaderDefault a {
    color: white;
    text-decoration: none;
}

.HeaderDefault a:hover,
.HeaderDefault a:focus
{
    font-family: "fb_regular","helvetica","arial";
    text-decoration: underline;
}

.modal-backdrop.show {
    opacity: .8;
}

.modal.show .modal-dialog {
    top: 0;
    margin-top: 15%;
}

.boostrapInvertcornersLeft {
    margin-right: -1px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    margin-left: 0 !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.boostrapInvertcornersRight {
    margin-left: -1px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    margin-right: 0 !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.boostrapFaIcons {
    font-size: 18px;
    padding: 5px 0;
}

.validatorInputError {
    font-family: "fb_regular","helvetica","arial";
    color: red;
    margin: -15px 0 3px 0;
    text-align: center;
    font-size: 16px;
}



.selectInput {

        

    .MuiAutocomplete-root {

      
        label {
            color: $inputColor;
            right: 50px;
            left: inherit;
        }

      
        &.Mui-focused, &.MuiAutocomplete-hasClearIcon {

            label {

                right: 5px;

            }
        }

        .MuiInputBase-root {

            /* background: white; */

            input {
                color: $inputColor;
            }

            fieldset {
                border-color: $borderInputs;
                text-align: right;
            }
            
        }

    }
    

}