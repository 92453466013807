/* // GENERAL MOBILE! */

.mobile .content-wrapper.twoColumns {
    display: block;
}

.mobile .content-wrapper.twoColumns .mainTable-wrapper {
    display: block;
}

.mobile .content-wrapper.twoColumns .mainContent {
    display: block;
}

.mobile .HomePage .Tasks {

    width: 100%;
    
}

.mobile .NavInner {
    display: none;
}


.owl-theme .owl-dots .owl-dot span {
    margin: 0 3px !important;
    padding: 0 !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #359ee5 !important;
}

/* END ---  GENERAL MOBILE! */

.mobile .Tasks .MobileView .carouselItem {
    display: block;
    background: white;
    text-align: center;
    padding: 0px 0 20px 0;
    margin: 0 0 20px 0;

    -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.44);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.44);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.44);
}

.mobile .Tasks .MobileView .carouselItem ul {
    padding: 0;
    margin: 0;
}

.mobile .Tasks .MobileView .carouselItem ul li {
    display: block;
    padding: 0;
}

.mobile .Tasks .MobileView .carouselItem h3 {
    background: #359ee517;
    padding: 5px 0;
    color: black;
    font-size: 20px;
    font-family: "fb_regular","helvetica","arial";
}

.mobile .Tasks .MobileView .carouselItem .statusLI {
    padding: 20px 0 20px 0;
}

.mobile .Tasks .MobileView .carouselItem h3 {
    background: #359ee517;
    font-size: 18px;
    color: black;
}

.mobile .Tasks .MobileView .carouselItem.yellowTrClass .statusLI  {
    border-top: 3px solid #fccf14;
    color: black;
}

.mobile .Tasks .MobileView .carouselItem.greenTrClass .statusLI  {
    border-top: 3px solid #02b201;
    color: white;
}

.mobile .Tasks .MobileView .carouselItem.redTrClass .statusLI  {
    border-top: 3px solid #ff2525;
    color: white;
}


.mobile .Tasks .MobileView .carouselItem .statusLI select {
    width: 80%;
    direction: rtl;
    padding: 0;
    text-align: center;
    border: none;
    font-family: "fb_regular","helvetica","arial";
    font-size: 18px;
    background: url('../img/icons/SVG/downSelect.svg') 15px center whitesmoke no-repeat;
    background-size: 10px;
    color: black;
    
    padding: 10px 15px 10px 20px;
    margin: 0px auto 0;
}

.mobile .Tasks .MobileView .carouselItem.redTrClass .statusLI select {
    border: 1px solid #ff2525;
}

.mobile .Tasks .MobileView .carouselItem.greenTrClass  .statusLI select {
    border: 1px solid #02b201;
}

.mobile .Tasks .MobileView .carouselItem.yellowTrClass .statusLI select {
    border: 1px solid #fccf14;
}




.mobile .Tasks .MobileView .carouselItem input {
    width: 90%;
    margin: 15px auto 15px;
    display: block;
    font-family: "fb_regular","helvetica","arial";
    background: whitesmoke;
}


.mobile .Tasks .MobileView .carouselItem button {
    border: none;
    padding: 0;
    color: rgb(226, 226, 226);    
    background: none;
}


.mobile .Tasks .MobileView .carouselItem button.active {
    color: #359ee5;
}

.mobile button.showHidden {
    display: block;
    margin: 0 0 20px 0;
}

.mobile .tableDisplay .trTable div:nth-child(3),
.mobile .tableDisplay .trTable div:nth-child(4),
.mobile .tableDisplay .trTable div:nth-child(5)
{
    display: none;
}
.mobile .tableDisplay .trTable.titlesTr div {
    border: none;
}

.mobile .newTask button:nth-child(2) {
    display: none;
}

.mobile h2.titStyle {
    font-size: 20px;
}

.mobile .clientScreen h2 {
    display: block;
}

.mobile .clientScreen .row.searchOnClients .col-9 {
    width: 100%;
    padding: 0;
}

.mobile section.contactsClient {
    width: 100%;
}

.mobile .SeniorContacts {
    width: 100%;
    
    overflow: hidden;
    overflow-x: scroll;
}

.mobile .seniorsTableContact {
    width: 1000px;
}

.mobile .filesWorker .formFileSection > div > div {
    width: 100%;
}

.mobile .filesWorker .btn.submitValidationFormBtn {
    display: block;
    margin: 0 auto 40px;
}

.mobile .LinksWorkerCont {
    width: 100%;

    overflow: hidden;
    overflow-x: scroll;
}

.mobile .LinksWorkerCont .overFlowDiv {
    width: 1000px;
}



.mobile .WorkersContacts {
    width: 100%;

    overflow: hidden;
    overflow-x: scroll;
}

.mobile .WorkersContacts .overFlowDiv {
    width: 1000px;
}

.mobile .AllFilesPage .selectClientsWorkers .input-group {
    margin-bottom: 20px;
}

.mobile .AllFilesPage .FilesWrapper {
    padding: 0 5%;
}

.mobile .AllFilesPage .selectorContent {
    width: 95%;
    margin: 0 auto;
}

.mobile .AllFilesPage .FilesWrapper .filePickUpBtn {
    margin: 15px 0 10px !important;
}


.mobile .reportClient .PlacementDataHistory {
    width: 100%;
    margin: 0;
    padding-left: 0;
}

.mobile .reportClient .PlacementDataHistory select {
    margin: 0;
    padding-left: 40px;
}

.mobile .reportClient .insertComment input {
    width: 100%;
}

.mobile .reportClient .insertComment button {
    display: block;
    width: 40%;
    margin: 5px 0 0 0;
}

.mobile .reportClient
{
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    margin-bottom: 0;

}

.mobile .reportClient .seniorsTableContact {
    width: 600px;
}
 










.mobile .contNewReport button {
    float: left;
}

.mobile .matchList {
    width: 100%;
}

.mobile .matchList .tableList .rowTable .tdTable {
    font-size: 14px;
}

.mobile .matchList .dropdown .results ul li {
    font-size: 14px;
}

.mobile .clientScreenSec .addBtnGreen,
.mobile .workerScreenSec .addBtnGreen
{
    float: none;
    display: block;
    margin: 0 auto;
}

.mobile .pannelSearch {
    margin: 20px 0 20px 0;
    padding: 30px 20px 20px;
}

.mobile .pannelSearch .divBoostrap {
    margin: 0 0 20px 0;
    display: block;
}

.mobile .savedWindow {
    top: 72px;
    right: 10px;
    text-align: center;
    line-height: 1.3;
    font-size: 16px;
    width: 150px;
    padding: 4px 0;
    background: #008000e8;
}

.mobile .configPage .formContainerAdmin {
    padding: 0 5%;
}

.mobile .newTaskModal .huklGi .wrapper {
    width: 93%;
}



.mobile .graphsContainer > div {
    width: 50%;
    margin: 0;
    padding: 5px;
}

.mobile .graphsContainer .QuadGraph {
    margin-bottom: 0px;
}

.mobile .graphsContainer .QuadGraph header {
    margin: 0 auto 10px ;
    min-height: 60px;
}

.mobile .QuadGraph header h3 {
    min-height: inherit;
    font-size: 18px;
}

.mobile .graphsContainer .QuadGraph .graphCont {
    padding: 10px;

}

.mobile .DataSummary .container {
    padding: 0;
}

.mobile .clientScreen section.headerSec .info ul {
    display: block;
}

.mobile .clientScreen section.headerSec .info ul li {
    display: block;
}

.mobile .clientScreen section.headerSec .info ul li.statusChange select {
    margin: 10px 0 0 0;
}



.mobile .reportClient .tableComments.SeniorContacts {
    width: 1000px;
    overflow: inherit;
}


.mobile .homeVisitsClient {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    margin-bottom: 0;
}

.mobile .homeVisitsClient .tableComments {
    width: 1000px;
}

.mobile .homeVisitsClient .tableComments .tdTable:nth-child(2) {
    padding: 0 20px;
    min-width: 400px;
}

.mobile .homeVisitsClient .tableComments .tdTable:nth-child(3) {
    padding: 0 20px;
}


.mobile .homeVisitsPage .signContainer {
    width: 100%;
    background: transparent;
    border: none;
}

.mobile .homeVisitsPage .dateTimeCont {
    margin: 0 0 20px 0;
}

.mobile .homeVisitsPage .signContainer canvas {
    width: 100%;
}


.mobile .homeVisitsClient {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
}

.header-scroll .mobile .pannelFixed {
    padding: 0;
    top: 30px;
    min-height: inherit;
    position: relative;
    width: 100%;
}


.UploadSignPics .allSigns {
    width: 100%;
    margin: 20px 0 50px 0;
} 

.UploadSignPics .allSigns li {
    width: 200px;
    
    background: white;
    padding: 0px;
    display: inline-block;
    vertical-align: top;

    margin: 0 0px 0 50px;
}
.UploadSignPics .allSigns li p {
    background: grey;
    color: white;
    margin: 0;
    width: 100%;
    text-align: center;

}

.UploadSignPics .allSigns li figure {
    background: center center white no-repeat;
    background-size: 90%;
    width: 100%;
    height: 160px;
}


.UploadSignPics .allSigns li img {
    padding: 15px;
    width: 100%;
}

.mobile {

    .tableDisplay {
        display: none;
    }



}